/*!
 * styling prjk_one
 */
 .dropdown-submenu,.dropdown-ovmenu{position:relative;}
.dropdown-ovmenu>.dropdown-menu{top:100%;left:0%;margin-top:-6px;margin-left:-1px;
-webkit-border-radius:0 6px 6px 6px;-moz-border-radius:0 6px 6px 6px;border-radius:0 6px 6px 6px; zzzbackground-color:#C06;}
.dropdown-submenu>.dropdown-menu{top:0;left:100%;margin-top:-6px;margin-left:-1px;
-webkit-border-radius:0 6px 6px 6px;-moz-border-radius:0 6px 6px 6px;border-radius:0 6px 6px 6px;}
footer .dropdown-ovmenu>.dropdown-menu{top:-100%;left:0%;margin-top:-6px;margin-left:-1px;
-webkit-border-radius:0 6px 6px 6px;-moz-border-radius:0 6px 6px 6px;border-radius:0 6px 6px 6px;}
.dropdown-ovmenu>.dropdown-submenu>.dropdown-menu{top:0;left:100%;margin-top:-6px;margin-left:-1px;
-webkit-border-radius:0 6px 6px 6px;-moz-border-radius:0 6px 6px 6px;border-radius:0 6px 6px 6px;}
.dropdown-submenu:hover>.dropdown-menu{display:block;}
.dropdown-ovmenu:hover>.dropdown-menu{display:block;}
.dropdown-submenu>a:after{display:block;content:" ";float:right;width:0;height:0;
border-color:transparent;border-style:solid;border-width:5px 0 5px 5px;border-left-color:#cccccc;margin-top:5px;margin-right:-10px;}
.dropdown-ovmenu>a:after{display:block;content:" ";float:right;width:0;height:0;
border-color:transparent;border-style:solid;border-width:5px 0 5px 5px;border-left-color:#cccccc;margin-top:5px;margin-right:-10px;}
.dropdown-submenu:hover>a:after{border-left-color:#ffffff;}
.dropdown-submenu.pull-left{float:none;}
.dropdown-submenu.pull-left>.dropdown-menu{left:-100%;margin-left:10px;
-webkit-border-radius:6px 0 6px 6px;-moz-border-radius:6px 0 6px 6px;border-radius:6px 0 6px 6px;}
[class*="col-"] {
	zzzfloat:none;
}
section {
	text-align:center;
	overflow:inherit;
	}
article {
	zzzdisplay:inline-block;
	vertical-align:top;
	text-align:left;
	}
.section_portfolio article {
	text-align:center;
	margin-bottom:20px;
	}
.section_portfolio article h4 {
	margin:0 auto;
	}			
.section_portfolio img {
	-moz-box-shadow: 0px 10px 8px rgba(48, 10, 75, 1);
    -webkit-box-shadow: 0px 0px 8px rgba(48, 10, 75, 1);
    box-shadow: 0px 0px 8px rgba(48, 10, 75, 1);
	margin:6px auto 12px auto;
	}	
article a {
	display:block;
	padding:12px 0 12px 0;
	}
#section_graphique article a {
	display:inline-block;
	padding:0;
	}
body {
	background-color:#0ee;
	}

@media (max-width: 380px) {
[class*="col-"] {
	width:100%;
	}
}
#main_article{.row();}
.section_main {
	.container();
	background-color:#fff;
	}
#zzPRJK_Parallax , #zzaccueil {
	width:100%;
	zzzzbackground-color:#f8f;
}

